import { FETCH_DASHBOARD_GENERAL, RECEIVE_DASHBOARD_GENERAL, FAILED_DASHBOARD_GENERAL } from '../../actions/dashboards/general'

const defaultState = {
    timestamp: [],
    data: []
}

export function fetchDashboardGeneral(state = defaultState, action){
    switch(action.type){
        case FETCH_DASHBOARD_GENERAL:
            return ({ timestamp: [], data: [], inProgress: true })
        case RECEIVE_DASHBOARD_GENERAL:
            return Object.assign({}, state, { timestamp: action.payload.timestamp, data: action.payload.value, inProgress: false })
        case FAILED_DASHBOARD_GENERAL:
            return ({ timestamp: [], data: [], inProgress: false })
        default:
            return state
    }
}