import { FETCH_MENU, RECEIVE_MENU, FAILED_MENU } from '../../actions/users/menu'
import cookie from 'react-cookies'

const defaultState = {
    menus: []
}

export function fetchMenu(state = defaultState, action){
    switch(action.type){
        case FETCH_MENU:
            return ({ menus: [], inProgress: true })
        case RECEIVE_MENU:
            var response = action.payload
            var menu = response.menus.sort((a, b) => (a.sequence > b.sequence) ? 1 : -1)
            var items = []

            menu.forEach((result, index) => {
                if(result.parent_id == null){
                    let children = []
                    menu.forEach((a, b) => {
                        if(a.parent_id == result.id){
                            let subchildren = []

                            menu.forEach((c, d) => {
                                if(c.parent_id == a.id){
                                    subchildren.push({
                                        id: c.name.toLowerCase(),
                                        title: c.name,
                                        type: c.type,
                                        url: c.url,
                                        icon: c.icon,
                                    })
                                }
                            })

                            if(subchildren.length > 0){
                                children.push({
                                    id: a.name.toLowerCase(),
                                    title: a.name,
                                    type: a.type,
                                    icon: a.icon,
                                    children: subchildren
                                })
                            } else {
                                children.push({
                                    id: a.name.toLowerCase(),
                                    title: a.name,
                                    type: a.type,
                                    url: a.url,
                                    icon: a.icon,
                                })
                            }
                        }
                    })

                    if(children.length > 0){
                        items.push({
                            id: result.name.toLowerCase(),
                            title: result.name,
                            type: result.type,
                            icon: result.icon,
                            children: children
                        })
                    } else {
                        items.push({
                            id: result.name.toLowerCase(),
                            title: result.name,
                            type: result.type,
                            icon: result.icon,
                        })
                    }
                }
            })
            let newMenu = {}
            newMenu['items'] = items

            cookie.save('menu', newMenu, {path: '/'})
            return Object.assign({}, state, { menus: newMenu, inProgress: false })
        case FAILED_MENU:
            return ({ menus: [], inProgress: false })
        default:
            return state
    }
}