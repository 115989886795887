import {
  FETCH_THEME,
  RECEIVE_THEME,
  FAILED_THEME,
} from "../../actions/programs/aGetTheme";

const defaultState = {
  themes: [],
  themeOptions: [],
  dashboards: [],
  themeAuthorized: false,
  inProgress: false,
};

function renameKeys(obj, newKeys) {
  const keyValues = Object.keys(obj).map((key) => {
    const newKey = newKeys[key] || key;
    return { [newKey]: obj[key] };
  });
  return Object.assign({}, ...keyValues);
}

export function fetchTheme(state = defaultState, action) {
  switch (action.type) {
    case FETCH_THEME:
      return {
        themes: [],
        themeOptions: [],
        dashboards: [],
        themeAuthorized: false,
        inProgress: true,
      };
    case RECEIVE_THEME:
      var payload = action.payload;
      var programs = action.programs;
      var list = [];
      var dashboard = [];

      payload.forEach((result, index) => {
        list.push({
          label: result.title,
          value: result.id,
          parent: result.program_id,
        });
      });

      programs.forEach((program) => {
        let themes = [];

        payload.forEach((result) => {
          if (program.id === result.program_id) {
            themes.push(result);
          }
        });

        program["themes"] = themes;
        dashboard.push(program);
      });

      return Object.assign({}, state, {
        themes: payload,
        themeOptions: list,
        dashboards: dashboard,
        themeAuthorized: action.isAuthorized,
        inProgress: false,
      });
    case FAILED_THEME:
      return {
        themes: [],
        themeOptions: [],
        dashboard: [],
        themeAuthorized: false,
        inProgress: false,
      };
    default:
      return state;
  }
}
