import cookie from "react-cookies";

const defaultState = {
  parentProgram: cookie.load("parentProgram") || null,
  parentTheme: cookie.load("parentTheme") || null,
  parentSubtheme: cookie.load("parentSubtheme") || null,
  parentActivity: cookie.load("parentActivity") || null,
  parentSubactivity: cookie.load("parentSubactivity") || null,
  parentStepactivity: cookie.load("parentStepactivity") || null,
  parentDirectorat: cookie.load("parentDirectorat") || null,
  parentSubdirectorat: cookie.load("parentSubdirectorat") || null,
  parentAgenda: cookie.load("parentAgenda") || null,
  parentKegiatan: cookie.load("parentKegiatan") || null,
  parentFile: cookie.load("parentFile") || null,
};

export function getSaveParent(state = defaultState, action) {
  switch (action.type) {
    case "SET_PROGRAM":
      return Object.assign({}, state, { parentProgram: action.payload });
    case "SET_THEME":
      return Object.assign({}, state, { parentTheme: action.payload });
    case "SET_SUBTHEME":
      return Object.assign({}, state, { parentSubtheme: action.payload });
    case "SET_ACTIVITY":
      return Object.assign({}, state, { parentActivity: action.payload });
    case "SET_SUBACTIVITY":
      return Object.assign({}, state, { parentSubactivity: action.payload });
    case "SET_STEPACTIVITY":
      return Object.assign({}, state, { parentStepactivity: action.payload });
    case "SET_DIRECTORAT":
      return Object.assign({}, state, { parentDirectorat: action.payload });
    case "SET_SUBDIRECTORAT":
      return Object.assign({}, state, { parentSubdirectorat: action.payload });
    case "SET_AGENDA":
      return Object.assign({}, state, { parentAgenda: action.payload });
    case "SET_KEGIATAN":
      return Object.assign({}, state, { parentKegiatan: action.payload });
    case "SET_FILE":
      return Object.assign({}, state, { parentFile: action.payload });
    default:
      return state;
  }
}
