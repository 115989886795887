import {
  FETCH_FILE,
  RECEIVE_FILE,
  FAILED_FILE,
} from "../../actions/programs/aGetFile";

const defaultState = {
  files: [],
  fileAuthorized: false,
  inProgress: false,
};

export function fetchFile(state = defaultState, action) {
  switch (action.type) {
    case FETCH_FILE:
      return { files: [], fileAuthorized: false, inProgress: true };
    case RECEIVE_FILE:
      return Object.assign({}, state, {
        files: action.payload,
        fileAuthorized: action.isAuthorized,
        inProgress: false,
      });
    case FAILED_FILE:
      return { files: [], fileAuthorized: false, inProgress: false };
    default:
      return state;
  }
}
