import axios from "axios";
import cookie from "react-cookies";
import { toast } from "react-toastify";
import * as API_LINKS from "../../../config/link";

export const FETCH_SUBDIRECTORAT = "FETCH_SUBDIRECTORAT";
export const RECEIVE_SUBDIRECTORAT = "RECEIVE_SUBDIRECTORAT";
export const FAILED_SUBDIRECTORAT = "FAILED_SUBDIRECTORAT";

export function fetchSubdirectorat(params) {
  const auth = cookie.load("token");

  return (dispatch, getState) => {
    dispatch({ type: FETCH_SUBDIRECTORAT });

    axios({
      method: "get",
      url: API_LINKS.SUBDIRECTORAT_LIST_URL,
      headers: {
        Authorization: auth,
      },
      params,
    })
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch({
              type: RECEIVE_SUBDIRECTORAT,
              payload: response.data.result,
              isAuthorized: response.data.isAuthorized,
            });
          } else {
            dispatch({ type: FAILED_SUBDIRECTORAT });
            return toast.error(response.data.message);
          }
        }
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 401) {
            dispatch({
              type: FAILED_SUBDIRECTORAT,
            });
            return toast.error(error.response.data.message);
          } else if (error.response.status === 403) {
            dispatch({
              type: FAILED_SUBDIRECTORAT,
            });
            return toast.error(error.response.data.message);
          } else if (error.response.status === 400) {
            dispatch({
              type: FAILED_SUBDIRECTORAT,
            });
            return toast.error(error.response.data.message);
          } else if (
            error.response.status === 404 ||
            error.response.status === 500
          ) {
            dispatch({
              type: FAILED_SUBDIRECTORAT,
            });
            return toast.error(
              "Server cannot be contacted! Please ask your system administrator!"
            );
          } else {
            dispatch({
              type: FAILED_SUBDIRECTORAT,
            });
            return toast.error(
              "Something went wrong... Please try again later..."
            );
          }
        } else if (error.request) {
          dispatch({
            type: FAILED_SUBDIRECTORAT,
          });
          return toast.error(
            "Request have no response! Please check on your internet connection and refresh this page."
          );
        } else {
          dispatch({
            type: FAILED_SUBDIRECTORAT,
          });
          return toast.error(
            "Something went wrong... Please try again later..."
          );
        }
      });
  };
}
