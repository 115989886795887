import { FETCH_DASHBOARD_AMOUNT, RECEIVE_DASHBOARD_AMOUNT, FAILED_DASHBOARD_AMOUNT } from '../../actions/dashboards/amount'

const defaultState = {
    amount: []
}

export function fetchDashboardAmount(state = defaultState, action){
    switch(action.type){
        case FETCH_DASHBOARD_AMOUNT:
            return ({ amount: [], inProgress: true })
        case RECEIVE_DASHBOARD_AMOUNT:
            let response = action.payload
            let amount = []
            
            response.forEach((result, index) => {
                let currentSplit = result.current.split(",")
                let current = currentSplit[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".") + `,${currentSplit[1]}`

                amount.push({
                    ...result,
                    "percentage": Math.ceil(result.percentage),
                    "title": result.type.charAt(0).toUpperCase() + result.type.slice(1) + " Transaction",
                    "current": current
                })
            })

            return Object.assign({}, state, { amount, inProgress: false })
        case FAILED_DASHBOARD_AMOUNT:
            return ({ amount: [], inProgress: false })
        default:
            return state
    }
}