import {
  FETCH_SUBDIRECTORAT,
  RECEIVE_SUBDIRECTORAT,
  FAILED_SUBDIRECTORAT,
} from "../../actions/programs/aGetSubdirectorat";

const defaultState = {
  subdirectorats: [],
  subdirectoratOptions: [],
  subdirectoratAuthorized: false,
  inProgress: false,
};

export function fetchSubdirectorat(state = defaultState, action) {
  switch (action.type) {
    case FETCH_SUBDIRECTORAT:
      return {
        subdirectorats: [],
        subdirectoratOptions: [],
        subdirectoratAuthorized: false,
        inProgress: true,
      };
    case RECEIVE_SUBDIRECTORAT:
      var payload = action.payload;
      var list = [];

      payload.forEach((result, index) => {
        list.push({
          label: result.title,
          value: result.id,
          parent: result.directorat_id,
        });
      });

      return Object.assign({}, state, {
        subdirectorats: payload,
        subdirectoratOptions: list,
        subdirectoratAuthorized: action.isAuthorized,
        inProgress: false,
      });
    case FAILED_SUBDIRECTORAT:
      return {
        subdirectorats: [],
        subdirectoratOptions: [],
        subdirectoratAuthorized: false,
        inProgress: false,
      };
    default:
      return state;
  }
}
