import { FETCH_REPORT, RECEIVE_REPORT, FAILED_REPORT } from '../../actions/programs/aGetReport'

const defaultState = {
    reports: [],
    reportAuthorized: false,
    inProgress: false
}

export function fetchReport(state = defaultState, action){
    switch(action.type){
        case FETCH_REPORT:
            return ({ reports: [], reportAuthorized: false, inProgress: true })
        case RECEIVE_REPORT:
            var payload = action.payload
        
            return Object.assign({}, state, { reports: payload, reportAuthorized: action.isAuthorized, inProgress: false })
        case FAILED_REPORT:
            return ({ reports: [], reportAuthorized: false, inProgress: false })
        default:
            return state
    }
}