import { FETCH_PROVINCE, RECEIVE_PROVINCE, FAILED_PROVINCE } from '../../actions/others/aGetProvince'

const defaultState = {
    provinces: [],
    provinceOptions: [],
    inProgress: false,
}

export function fetchProvince(state = defaultState, action){
    switch(action.type){
        case FETCH_PROVINCE:
            return ({ provinces: [], provinceOptions: [], inProgress: true })
        case RECEIVE_PROVINCE:
            var payload = action.payload
            var list = []

            payload.forEach((result, index) => {
                list.push({
                    label: result.name,
                    value: result.id
                })
            })

            return Object.assign({}, state, { provinces: payload, provinceOptions: list, inProgress: false })
        case FAILED_PROVINCE:
            return ({ provinces: [], provinceOptions: [], inProgress: false })
        default:
            return state
    }
}