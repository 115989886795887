import { FETCH_CURRENCY, RECEIVE_CURRENCY, FAILED_CURRENCY } from '../../actions/currencies/list'

const defaultState = {
    currencies: []
}

export function fetchCurrency(state = defaultState, action){
    switch(action.type){
        case FETCH_CURRENCY:
            return ({ currencies: [], inProgress: true })
        case RECEIVE_CURRENCY:
            let response = action.payload
            let list = []

            response.forEach((result, index) => {
                list.push({
                    label: result.code + ` (Rp. ${result.rate})`,
                    value: result.id,
                    rate: result.rate
                })
            })

            return Object.assign({}, state, { currencies: response, currencyOptions: list, inProgress: false })
        case FAILED_CURRENCY:
            return ({ currencies: [], inProgress: false })
        default:
            return state
    }
}