import { FETCH_SUBTHEME, RECEIVE_SUBTHEME, FAILED_SUBTHEME } from '../../actions/programs/aGetSubtheme'

const defaultState = {
    subthemes: [],
    subthemeOptions: [],
    subthemeAuthorized: false,
    inProgress: false
}

export function fetchSubtheme(state = defaultState, action){
    switch(action.type){
        case FETCH_SUBTHEME:
            return ({ subthemes: [], subthemeOptions: [], subthemeAuthorized: false, inProgress: true })
        case RECEIVE_SUBTHEME:
            var payload = action.payload
            var list = []

            payload.forEach((result, index) => {
                list.push({
                    label: result.title,
                    value: result.id,
                    parent: result.theme_id
                })
            })

            return Object.assign({}, state, { subthemes: payload, subthemeOptions: list, subthemeAuthorized: action.isAuthorized, inProgress: false })
        case FAILED_SUBTHEME:
            return ({ subthemes: [], subthemeOptions: [], subthemeAuthorized: false, inProgress: false })
        default:
            return state
    }
}