import { FETCH_STEPACTIVITY, RECEIVE_STEPACTIVITY, FAILED_STEPACTIVITY } from '../../actions/programs/aGetStepactivity'

const defaultState = {
    stepactivities: [],
    stepactivityOptions: [],
    stepactivityAuthorized: false,
    inProgress: false
}

export function fetchStepactivity(state = defaultState, action){
    switch(action.type){
        case FETCH_STEPACTIVITY:
            return ({ stepactivities: [], stepactivityOptions: [], stepactivityAuthorized: false, inProgress: true })
        case RECEIVE_STEPACTIVITY:
            var payload = action.payload
            var list = []

            payload.forEach((result, index) => {
                list.push({
                    label: result.title,
                    value: result.id,
                    parent: result.subactivity_id
                })
            })

            return Object.assign({}, state, { stepactivities: payload, stepactivityOptions: list, stepactivityAuthorized: action.isAuthorized, inProgress: false })
        case FAILED_STEPACTIVITY:
            return ({ stepactivities: [], stepactivityOptions: [], stepactivityAuthorized: false, inProgress: false })
        default:
            return state
    }
}