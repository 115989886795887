import { FETCH_TYPE, RECEIVE_TYPE, FAILED_TYPE } from '../../actions/tasks/typeList'

const defaultState = {
    types: [],
    typeOptions: []
}

export function fetchTypeTask(state = defaultState, action){
    switch(action.type){
        case FETCH_TYPE:
            return ({ types: [], inProgress: true })
        case RECEIVE_TYPE:
            var response = action.payload
            var list = []

            response.forEach((result, index) => {
                list.push({
                    label: result.name,
                    value: result.id
                })
            })
            
            return Object.assign({}, state, { types: response, typeOptions: list, inProgress: false })
        case FAILED_TYPE:
            return ({ types: [], inProgress: false })
        default:
            return state
    }
}