import {
  FETCH_AGENDA,
  RECEIVE_AGENDA,
  FAILED_AGENDA,
} from "../../actions/programs/aGetAgenda";

const defaultState = {
  agendas: [],
  agendaOptions: [],
  agendaAuthorized: false,
  inProgress: false,
};

export function fetchAgenda(state = defaultState, action) {
  switch (action.type) {
    case FETCH_AGENDA:
      return {
        agendas: [],
        agendaOptions: [],
        agendaAuthorized: false,
        inProgress: true,
      };
    case RECEIVE_AGENDA:
      var payload = action.payload;
      var list = [];

      payload.forEach((result, index) => {
        list.push({
          label: result.title,
          value: result.id,
          parent: result.subdirectorat_id,
        });
      });

      return Object.assign({}, state, {
        agendas: payload,
        agendaOptions: list,
        agendaAuthorized: action.isAuthorized,
        inProgress: false,
      });
    case FAILED_AGENDA:
      return {
        agendas: [],
        agendaOptions: [],
        agendaAuthorized: false,
        inProgress: false,
      };
    default:
      return state;
  }
}
