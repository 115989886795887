import { FETCH_REGENCY, RECEIVE_REGENCY, FAILED_REGENCY } from '../../actions/others/aGetRegency'

const defaultState = {
    regencies: [],
    regencyOptions: [],
    inProgress: false,
}

export function fetchRegency(state = defaultState, action){
    switch(action.type){
        case FETCH_REGENCY:
            return ({ regencies: [], regencyOptions: [], inProgress: true })
        case RECEIVE_REGENCY:
            var payload = action.payload
            var list = []

            payload.forEach((result, index) => {
                list.push({
                    label: result.name,
                    value: result.id,
                    parent: result.province_id
                })
            })

            return Object.assign({}, state, { regencies: payload, regencyOptions: list, inProgress: false })
        case FAILED_REGENCY:
            return ({ regencies: [], regencyOptions: [], inProgress: false })
        default:
            return state
    }
}