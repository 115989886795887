import {
  FETCH_PERMISSION,
  RECEIVE_PERMISSION,
  FAILED_PERMISSION,
} from "../../actions/others/aGetPermission";

const defaultState = {
  permissions: [],
  inProgress: false,
};

export function fetchPermission(state = defaultState, action) {
  switch (action.type) {
    case FETCH_PERMISSION:
      return { permissions: [], inProgress: true };
    case RECEIVE_PERMISSION:
      var payload = action.payload;

      return Object.assign({}, state, {
        permissions: payload,
        inProgress: false,
      });
    case FAILED_PERMISSION:
      return { permissions: [], inProgress: false };
    default:
      return state;
  }
}
