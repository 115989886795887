import { FETCH_TAG, RECEIVE_TAG, FAILED_TAG } from '../../actions/others/aGetTag'

const defaultState = {
    tags: [],
    tagOptions: [],
    inProgress: false,
}

export function fetchTag(state = defaultState, action){
    switch(action.type){
        case FETCH_TAG:
            return ({ tags: [], tagOptions: [], inProgress: true })
        case RECEIVE_TAG:
            var payload = action.payload
            var list = []

            payload.forEach((result, index) => {
                list.push({
                    label: result.name,
                    value: result.id
                })
            })

            return Object.assign({}, state, { tags: payload, tagOptions: list, inProgress: false })
        case FAILED_TAG:
            return ({ tags: [], tagOptions: [], inProgress: false })
        default:
            return state
    }
}