import { FETCH_USER, RECEIVE_USER, FAILED_USER } from '../../actions/users/aGetUser';

const defaultState = {
    users: [],
    userCount: 0,
    adminCount: 0,
    totalCount: 0,
    userOptions: [],
    inProgress: false
}

export function fetchUser(state = defaultState, action){
    switch(action.type){
        case FETCH_USER:
            return ({ users: [], userOptions: [], userCount: 0, adminCount: 0, totalCount: 0, inProgress: true })
        case RECEIVE_USER:
            const payload = action.payload;
            const adminCount = payload.filter((user) => user.role_id === 1).length;
            const userCount = payload.filter((user) => user.role_id === 2).length;

            let list = [];

            payload.forEach((user) => {
                list.push({
                    label: user.name,
                    value: user.id
                })
            })

            return Object.assign({}, state, { users: payload, userOptions: list, adminCount, userCount, totalCount: payload.length, inProgress: false })
        case FAILED_USER:
            return ({ users: [], userOptions: [], userCount: 0, adminCount: 0, totalCount: 0, inProgress: false })
        default:
            return state;
    }
}