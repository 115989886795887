import { FETCH_SUBACTIVITY, RECEIVE_SUBACTIVITY, FAILED_SUBACTIVITY } from '../../actions/programs/aGetSubactivity'

const defaultState = {
    subactivities: [],
    subactivityOptions: [],
    subactivityAuthorized: false,
    inProgress: false
}

export function fetchSubactivity(state = defaultState, action){
    switch(action.type){
        case FETCH_SUBACTIVITY:
            return ({ subactivities: [], subactivityOptions: [], subactivityAuthorized: false, inProgress: true })
        case RECEIVE_SUBACTIVITY:
            var payload = action.payload
            var list = []

            payload.forEach((result, index) => {
                list.push({
                    label: result.title,
                    value: result.id,
                    parent: result.activity_id
                })
            })

            return Object.assign({}, state, { subactivities: payload, subactivityOptions: list, subactivityAuthorized: action.isAuthorized, inProgress: false })
        case FAILED_SUBACTIVITY:
            return ({ subactivities: [], subactivityOptions: [], subactivityAuthorized: false, inProgress: false })
        default:
            return state
    }
}