import { FETCH_PHOTO, RECEIVE_PHOTO, FAILED_PHOTO } from '../../actions/users/getPhoto'

const defaultState = {
    photo: []
}

export function fetchPhoto(state = defaultState, action){
    switch(action.type){
        case FETCH_PHOTO:
            return ({ photo: [], inProgress: true })
        case RECEIVE_PHOTO:
            return Object.assign({}, state, { photo: action.image, inProgress: false })
        case FAILED_PHOTO:
            return ({ photo: [], inProgress: false })
        default:
            return state
    }
}