import {
  FETCH_KEGIATAN,
  RECEIVE_KEGIATAN,
  FAILED_KEGIATAN,
} from "../../actions/programs/aGetKegiatan";

const defaultState = {
  kegiatans: [],
  kegiatanOptions: [],
  kegiatanAuthorized: false,
  inProgress: false,
};

export function fetchKegiatan(state = defaultState, action) {
  switch (action.type) {
    case FETCH_KEGIATAN:
      return {
        kegiatans: [],
        kegiatanOptions: [],
        kegiatanAuthorized: false,
        inProgress: true,
      };
    case RECEIVE_KEGIATAN:
      var payload = action.payload;
      var list = [];

      payload.forEach((result, index) => {
        list.push({
          label: result.title,
          value: result.id,
          parent: result.agenda_id,
        });
      });

      return Object.assign({}, state, {
        kegiatans: payload,
        kegiatanOptions: list,
        kegiatanAuthorized: action.isAuthorized,
        inProgress: false,
      });
    case FAILED_KEGIATAN:
      return {
        kegiatans: [],
        kegiatanOptions: [],
        kegiatanAuthorized: false,
        inProgress: false,
      };
    default:
      return state;
  }
}
