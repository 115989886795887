import { FETCH_TARGET, RECEIVE_TARGET, FAILED_TARGET } from '../../actions/others/aGetTarget'

const defaultState = {
    targets: [],
    targetOptions: [],
    inProgress: false,
}

export function fetchTarget(state = defaultState, action){
    switch(action.type){
        case FETCH_TARGET:
            return ({ targets: [], targetOptions: [], inProgress: true })
        case RECEIVE_TARGET:
            var payload = action.payload
            var list = []

            payload.forEach((result, index) => {
                list.push({
                    label: result.name,
                    value: result.id
                })
            })

            return Object.assign({}, state, { target: payload, targetOptions: list, inProgress: false })
        case FAILED_TARGET:
            return ({ target: [], targetOptions: [], inProgress: false })
        default:
            return state
    }
}