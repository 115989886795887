const URL = "https://agroapi.dinama.id";
// const URL = 'http://localhost:8000'

const BASE_URL = URL + "/api"; // Development
const FILE_URL = URL + "/files";

// User
const USER_URL = BASE_URL + "/user";
const USER_LIST_URL = USER_URL + "/get";
const USER_ADD_URL = USER_URL + "/add";
const USER_UPDATE_URL = USER_URL + "/update";
const USER_DELETE_URL = USER_URL + "/delete";
const USER_CHANGE_PASSWORD_URL = USER_URL + "/change-password";
const USER_PHOTO_URL = USER_URL + "/picture";
const USER_PHOTO_UPLOAD_URL = USER_PHOTO_URL + "/upload";
const USER_PHOTO_LIST_URL = USER_PHOTO_URL + "/get";
const USER_PHOTO_UPDATE_URL = USER_PHOTO_URL + "/update";

const LOGIN_URL = BASE_URL + "/login";
const LOGOUT_URL = BASE_URL + "/logout";

// Menu
const MENU_URL = BASE_URL + "/menu";
const MENU_LIST_URL = MENU_URL + "/get";

// Role
const ROLE_URL = BASE_URL + "/role";
const ROLE_LIST_URL = ROLE_URL + "/get";
const ROLE_ADD_URL = ROLE_URL + "/add";
const ROLE_UPDATE_URL = ROLE_URL + "/update";
const ROLE_DELETE_URL = ROLE_URL + "/delete";

// Department
const DEPARTMENT_URL = BASE_URL + "/department";
const DEPARTMENT_LIST_URL = DEPARTMENT_URL + "/get";
const DEPARTMENT_ADD_URL = DEPARTMENT_URL + "/add";
const DEPARTMENT_UPDATE_URL = DEPARTMENT_URL + "/update";
const DEPARTMENT_DELETE_URL = DEPARTMENT_URL + "/delete";

// Task Type
const TYPE_TASK_URL = BASE_URL + "/type/task";
const TYPE_TASK_LIST_URL = TYPE_TASK_URL + "/get";
const TYPE_TASK_ADD_URL = TYPE_TASK_URL + "/add";
const TYPE_TASK_UPDATE_URL = TYPE_TASK_URL + "/update";
const TYPE_TASK_DELETE_URL = TYPE_TASK_URL + "/delete";

// Task
const TASK_URL = BASE_URL + "/task";
const TASK_LIST_URL = TASK_URL + "/get";
const TASK_ADD_URL = TASK_URL + "/add";
const TASK_UPDATE_URL = TASK_URL + "/update";
const TASK_DELETE_URL = TASK_URL + "/delete";
const TASK_APPROVE_URL = TASK_URL + "/approve";
const TASK_REJECT_URL = TASK_URL + "/reject";

// PBRC
const TASK_PBRC_URL = BASE_URL + "/task-pbrc";
const TASK_PBRC_LIST_URL = TASK_PBRC_URL + "/get";
const TASK_PBRC_ADD_URL = TASK_PBRC_URL + "/add";
const TASK_PBRC_UPDATE_URL = TASK_PBRC_URL + "/update";
const TASK_PBRC_DELETE_URL = TASK_PBRC_URL + "/delete";
const TASK_PBRC_APPROVE_URL = TASK_PBRC_URL + "/approve";
const TASK_PBRC_REJECT_URL = TASK_PBRC_URL + "/reject";

// Dashboard
const DASHBOARD_URL = BASE_URL + "/dashboard";
const DASHBOARD_GENERAL_URL = DASHBOARD_URL + "/general";
const DASHBOARD_AMOUNT_URL = DASHBOARD_URL + "/amount";
const DASHBOARD_GENERAL_PBRC_URL = DASHBOARD_URL + "/general-pbrc";
const DASHBOARD_AMOUNT_PBRC_URL = DASHBOARD_URL + "/amount-pbrc";

// Currency
const CURRENCY_URL = BASE_URL + "/currency";
const CURRENCY_LIST_URL = CURRENCY_URL + "/get";
const CURRENCY_ADD_URL = CURRENCY_URL + "/add";
const CURRENCY_UPDATE_URL = CURRENCY_URL + "/update";
const CURRENCY_DELETE_URL = CURRENCY_URL + "/delete";

// Program
const PROGRAM_URL = BASE_URL + "/program";
const PROGRAM_LIST_URL = PROGRAM_URL + "/get";
const PROGRAM_ADD_URL = PROGRAM_URL + "/add";
const PROGRAM_UPDATE_URL = PROGRAM_URL + "/update";
const PROGRAM_DELETE_URL = PROGRAM_URL + "/delete";

// Theme
const THEME_URL = BASE_URL + "/theme";
const THEME_LIST_URL = THEME_URL + "/get";
const THEME_ADD_URL = THEME_URL + "/add";
const THEME_UPDATE_URL = THEME_URL + "/update";
const THEME_DELETE_URL = THEME_URL + "/delete";

// Subtheme
const SUBTHEME_URL = BASE_URL + "/sub-theme";
const SUBTHEME_LIST_URL = SUBTHEME_URL + "/get";
const SUBTHEME_ADD_URL = SUBTHEME_URL + "/add";
const SUBTHEME_UPDATE_URL = SUBTHEME_URL + "/update";
const SUBTHEME_DELETE_URL = SUBTHEME_URL + "/delete";

// Activity
const ACTIVITY_URL = BASE_URL + "/activity";
const ACTIVITY_LIST_URL = ACTIVITY_URL + "/get";
const ACTIVITY_ADD_URL = ACTIVITY_URL + "/add";
const ACTIVITY_UPDATE_URL = ACTIVITY_URL + "/update";
const ACTIVITY_DELETE_URL = ACTIVITY_URL + "/delete";

// Subactivity
const SUBACTIVITY_URL = BASE_URL + "/sub-activity";
const SUBACTIVITY_LIST_URL = SUBACTIVITY_URL + "/get";
const SUBACTIVITY_ADD_URL = SUBACTIVITY_URL + "/add";
const SUBACTIVITY_UPDATE_URL = SUBACTIVITY_URL + "/update";
const SUBACTIVITY_DELETE_URL = SUBACTIVITY_URL + "/delete";

// Stepactivity
const STEPACTIVITY_URL = BASE_URL + "/step-activity";
const STEPACTIVITY_LIST_URL = STEPACTIVITY_URL + "/get";
const STEPACTIVITY_ADD_URL = STEPACTIVITY_URL + "/add";
const STEPACTIVITY_UPDATE_URL = STEPACTIVITY_URL + "/update";
const STEPACTIVITY_DELETE_URL = STEPACTIVITY_URL + "/delete";

// Directorat
const DIRECTORAT_URL = BASE_URL + "/directorat";
const DIRECTORAT_LIST_URL = DIRECTORAT_URL + "/get";
const DIRECTORAT_ADD_URL = DIRECTORAT_URL + "/add";
const DIRECTORAT_UPDATE_URL = DIRECTORAT_URL + "/update";
const DIRECTORAT_DELETE_URL = DIRECTORAT_URL + "/delete";

// Sub Directorat
const SUBDIRECTORAT_URL = BASE_URL + "/sub-directorat";
const SUBDIRECTORAT_LIST_URL = SUBDIRECTORAT_URL + "/get";
const SUBDIRECTORAT_ADD_URL = SUBDIRECTORAT_URL + "/add";
const SUBDIRECTORAT_UPDATE_URL = SUBDIRECTORAT_URL + "/update";
const SUBDIRECTORAT_DELETE_URL = SUBDIRECTORAT_URL + "/delete";

// Agenda
const AGENDA_URL = BASE_URL + "/agenda";
const AGENDA_LIST_URL = AGENDA_URL + "/get";
const AGENDA_ADD_URL = AGENDA_URL + "/add";
const AGENDA_UPDATE_URL = AGENDA_URL + "/update";
const AGENDA_DELETE_URL = AGENDA_URL + "/delete";

// Kegiatan
const KEGIATAN_URL = BASE_URL + "/kegiatan";
const KEGIATAN_LIST_URL = KEGIATAN_URL + "/get";
const KEGIATAN_ADD_URL = KEGIATAN_URL + "/add";
const KEGIATAN_UPDATE_URL = KEGIATAN_URL + "/update";
const KEGIATAN_DELETE_URL = KEGIATAN_URL + "/delete";

// Report
const REPORT_URL = BASE_URL + "/report";
const REPORT_LIST_URL = REPORT_URL + "/get";
const REPORT_ADD_URL = REPORT_URL + "/add";
const REPORT_UPDATE_URL = REPORT_URL + "/update";
const REPORT_DELETE_URL = REPORT_URL + "/delete";

// Location
const PROVINCE_LIST_URL = BASE_URL + "/province/get";
const REGENCY_LIST_URL = BASE_URL + "/regency/get";
const TAG_LIST_URL = BASE_URL + "/tag/get";
const TARGET_LIST_URL = BASE_URL + "/target/get";

const ASSIGNEE_URL = BASE_URL + "/assignee";
const ASSIGNEE_LIST_URL = ASSIGNEE_URL + "/get";
const ASSIGNEE_ADD_URL = ASSIGNEE_URL + "/add";
const ASSIGNEE_DELETE_URL = ASSIGNEE_URL + "/delete";

const UPLOAD_URL = BASE_URL + "/upload";
const DELETE_FILE_URL = UPLOAD_URL + "/delete";
const FILTER_URL = BASE_URL + "/filter";

const GET_REPOSITORY_URL = BASE_URL + "/file/get";
const UPLOAD_REPORSITORY_URL = BASE_URL + "/file/upload";
const DELETE_REPOSITORY_URL = BASE_URL + "/file/delete";

const PERMISSION_URL = BASE_URL + "/permission";
const PERMISSION_LIST_URL = PERMISSION_URL + "/get";
const PERMISSION_ADD_URL = PERMISSION_URL + "/add";
const PERMISSION_DELETE_URL = PERMISSION_URL + "/delete";

export {
  BASE_URL,
  USER_URL,
  USER_LIST_URL,
  USER_ADD_URL,
  USER_UPDATE_URL,
  USER_DELETE_URL,
  USER_CHANGE_PASSWORD_URL,
  USER_PHOTO_URL,
  USER_PHOTO_LIST_URL,
  USER_PHOTO_UPDATE_URL,
  USER_PHOTO_UPLOAD_URL,
  LOGIN_URL,
  LOGOUT_URL,
  MENU_URL,
  MENU_LIST_URL,
  ROLE_URL,
  ROLE_LIST_URL,
  ROLE_ADD_URL,
  ROLE_UPDATE_URL,
  ROLE_DELETE_URL,
  DEPARTMENT_URL,
  DEPARTMENT_LIST_URL,
  DEPARTMENT_ADD_URL,
  DEPARTMENT_UPDATE_URL,
  DEPARTMENT_DELETE_URL,
  TYPE_TASK_URL,
  TYPE_TASK_LIST_URL,
  TYPE_TASK_ADD_URL,
  TYPE_TASK_UPDATE_URL,
  TYPE_TASK_DELETE_URL,
  TASK_URL,
  TASK_APPROVE_URL,
  TASK_LIST_URL,
  TASK_ADD_URL,
  TASK_UPDATE_URL,
  TASK_DELETE_URL,
  TASK_REJECT_URL,
  TASK_PBRC_URL,
  TASK_PBRC_LIST_URL,
  TASK_PBRC_ADD_URL,
  TASK_PBRC_UPDATE_URL,
  TASK_PBRC_DELETE_URL,
  TASK_PBRC_APPROVE_URL,
  TASK_PBRC_REJECT_URL,
  DASHBOARD_URL,
  DASHBOARD_GENERAL_URL,
  DASHBOARD_AMOUNT_URL,
  DASHBOARD_GENERAL_PBRC_URL,
  DASHBOARD_AMOUNT_PBRC_URL,
  CURRENCY_URL,
  CURRENCY_LIST_URL,
  CURRENCY_ADD_URL,
  CURRENCY_UPDATE_URL,
  CURRENCY_DELETE_URL,
  PROGRAM_URL,
  PROGRAM_LIST_URL,
  PROGRAM_ADD_URL,
  PROGRAM_UPDATE_URL,
  PROGRAM_DELETE_URL,
  THEME_URL,
  THEME_LIST_URL,
  THEME_ADD_URL,
  THEME_UPDATE_URL,
  THEME_DELETE_URL,
  SUBTHEME_URL,
  SUBTHEME_LIST_URL,
  SUBTHEME_ADD_URL,
  SUBTHEME_UPDATE_URL,
  SUBTHEME_DELETE_URL,
  ACTIVITY_URL,
  ACTIVITY_LIST_URL,
  ACTIVITY_ADD_URL,
  ACTIVITY_UPDATE_URL,
  ACTIVITY_DELETE_URL,
  SUBACTIVITY_URL,
  SUBACTIVITY_LIST_URL,
  SUBACTIVITY_ADD_URL,
  SUBACTIVITY_UPDATE_URL,
  SUBACTIVITY_DELETE_URL,
  STEPACTIVITY_URL,
  STEPACTIVITY_LIST_URL,
  STEPACTIVITY_ADD_URL,
  STEPACTIVITY_UPDATE_URL,
  STEPACTIVITY_DELETE_URL,
  REPORT_URL,
  REPORT_LIST_URL,
  REPORT_ADD_URL,
  REPORT_UPDATE_URL,
  REPORT_DELETE_URL,
  PROVINCE_LIST_URL,
  REGENCY_LIST_URL,
  TAG_LIST_URL,
  TARGET_LIST_URL,
  ASSIGNEE_LIST_URL,
  ASSIGNEE_ADD_URL,
  ASSIGNEE_DELETE_URL,
  UPLOAD_URL,
  DELETE_FILE_URL,
  FILE_URL,
  FILTER_URL,
  DIRECTORAT_LIST_URL,
  DIRECTORAT_ADD_URL,
  DIRECTORAT_UPDATE_URL,
  DIRECTORAT_DELETE_URL,
  SUBDIRECTORAT_LIST_URL,
  SUBDIRECTORAT_ADD_URL,
  SUBDIRECTORAT_UPDATE_URL,
  SUBDIRECTORAT_DELETE_URL,
  AGENDA_LIST_URL,
  AGENDA_ADD_URL,
  AGENDA_UPDATE_URL,
  AGENDA_DELETE_URL,
  KEGIATAN_LIST_URL,
  KEGIATAN_ADD_URL,
  KEGIATAN_UPDATE_URL,
  KEGIATAN_DELETE_URL,
  UPLOAD_REPORSITORY_URL,
  DELETE_REPOSITORY_URL,
  GET_REPOSITORY_URL,
  PERMISSION_LIST_URL,
  PERMISSION_ADD_URL,
  PERMISSION_DELETE_URL,
};
