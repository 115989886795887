import { FETCH_ASSIGNEE, RECEIVE_ASSIGNEE, FAILED_ASSIGNEE } from '../../actions/others/aGetAssignee'

const defaultState = {
    assignees: [],
    inProgress: false,
}

export function fetchAssignee(state = defaultState, action){
    switch(action.type){
        case FETCH_ASSIGNEE:
            return ({ assignees: [], inProgress: true })
        case RECEIVE_ASSIGNEE:
            var payload = action.payload

            return Object.assign({}, state, { assignees: payload, inProgress: false })
        case FAILED_ASSIGNEE:
            return ({ assignees: [], inProgress: false })
        default:
            return state
    }
}