import { FETCH_PROGRAM, RECEIVE_PROGRAM, FAILED_PROGRAM } from '../../actions/programs/aGetProgram'

const defaultState = {
    programs: [],
    programOptions: [],
    inProgress: false
}

export function fetchProgram(state = defaultState, action){
    switch(action.type){
        case FETCH_PROGRAM:
            return ({ programs: [], programOptions: [], inProgress: true })
        case RECEIVE_PROGRAM:
            var payload = action.payload
            var list = []

            payload.forEach((result, index) => {
                list.push({
                    label: result.title,
                    value: result.id
                })
            })

            return Object.assign({}, state, { programs: payload, programOptions: list, inProgress: false })
        case FAILED_PROGRAM:
            return ({ programs: [], programOptions: [], inProgress: false })
        default:
            return state
    }
}