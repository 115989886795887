import { FETCH_ACTIVITY, RECEIVE_ACTIVITY, FAILED_ACTIVITY } from '../../actions/programs/aGetActivity'

const defaultState = {
    activities: [],
    activityOptions: [],
    activityAuthorized: false,
    inProgress: false
}

export function fetchActivity(state = defaultState, action){
    switch(action.type){
        case FETCH_ACTIVITY:
            return ({ activities: [], activityOptions: [], activityAuthorized: false, inProgress: true })
        case RECEIVE_ACTIVITY:
            var payload = action.payload
            var list = []

            payload.forEach((result, index) => {
                list.push({
                    label: result.title,
                    value: result.id,
                    parent: result.subtheme_id
                })
            })

            return Object.assign({}, state, { activities: payload, activityOptions: list, activityAuthorized: action.isAuthorized, inProgress: false })
        case FAILED_ACTIVITY:
            return ({ activities: [], activityOptions: [], activityAuthorized: false, inProgress: false })
        default:
            return state
    }
}