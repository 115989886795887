import { combineReducers } from "redux";
import reducer from "../../store/reducer";

import { fetchLogin } from "./users/auth";
import { fetchTask } from "./tasks/list";
import { fetchDepartment } from "./departments/list";
import { fetchTypeTask } from "./tasks/typeList";
import { fetchMenu } from "./users/menu";
import { fetchDashboardGeneral } from "./dashboards/general";
import { fetchDashboardAmount } from "./dashboards/amount";
import { fetchCurrency } from "./currencies/list";
import { fetchPhoto } from "./users/getPhoto";
// import { fetchReportType } from './report/type'
// import { fetchReportAmount } from  './report/amount'
// import { fetchTaskPBRC } from './taskpbrc/list'
// import { fetchDashboardPBRCGeneral } from './dashboards/generalpbrc'
// import { fetchDashboardPBRCAmount } from './dashboards/amountpbrc'

import { fetchUser } from "./users/redGetUser";
import { fetchRole } from "./users/redGetRole";
import { fetchProgram } from "./programs/redGetProgram";
import { fetchTheme } from "./programs/redGetTheme";
import { fetchSubtheme } from "./programs/redGetSubtheme";
import { fetchActivity } from "./programs/redGetActivity";
import { fetchSubactivity } from "./programs/redGetSubactivity";
import { fetchStepactivity } from "./programs/redGetStepactivity";
import { fetchReport } from "./programs/redGetReport";

import { fetchProvince } from "./others/redGetProvince";
import { fetchRegency } from "./others/redGetRegency";
import { fetchTag } from "./others/redGetTag";
import { fetchTarget } from "./others/redGetTarget";
import { fetchAssignee } from "./others/redGetAssignee";
import { getSaveParent } from "./others/redSaveParent";

import { fetchDirectorat } from "./programs/redGetDirectorat";
import { fetchSubdirectorat } from "./programs/redGetSubdirectorat";
import { fetchAgenda } from "./programs/redGetAgenda";
import { fetchKegiatan } from "./programs/redGetKegiatan";
import { fetchFile } from "./programs/redGetFile";
import { fetchPermission } from "./others/redGetPermission";

const rootReducers = combineReducers({
  taskStore: fetchTask,
  departmentStore: fetchDepartment,
  typeTaskStore: fetchTypeTask,
  menuStore: fetchMenu,
  dashboardStore: fetchDashboardGeneral,
  dashboardAmountStore: fetchDashboardAmount,
  currencyStore: fetchCurrency,
  photoStore: fetchPhoto,
  // reportTypeStore: fetchReportType,
  // reportAmountStore: fetchReportAmount,
  // taskPBRCStore: fetchTaskPBRC,
  // dashboardPBRCStore: fetchDashboardPBRCGeneral,
  // dashboardAmountPBRCStore: fetchDashboardPBRCAmount,

  // Agro
  reducerStore: reducer,
  loginStore: fetchLogin,
  userStore: fetchUser,
  roleStore: fetchRole,
  programStore: fetchProgram,
  themeStore: fetchTheme,
  subthemeStore: fetchSubtheme,
  activityStore: fetchActivity,
  subactivityStore: fetchSubactivity,
  stepactivityStore: fetchStepactivity,
  reportStore: fetchReport,

  provinceStore: fetchProvince,
  regencyStore: fetchRegency,
  tagStore: fetchTag,
  targetStore: fetchTarget,
  assigneeStore: fetchAssignee,
  saveParentStore: getSaveParent,

  directoratStore: fetchDirectorat,
  subdirectoratStore: fetchSubdirectorat,
  agendaStore: fetchAgenda,
  kegiatanStore: fetchKegiatan,
  fileStore: fetchFile,
  permissionStore: fetchPermission,
});

export default rootReducers;
